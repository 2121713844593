.vacancies {
	&__body {
		position: relative;
	}

	&__appeal {
		max-width: 750px;
		font-family: $fontPlayfair;
		font-weight: 400;
		font-size: clamp(16px, (24/1366) * 100vw, 24px);
		margin-bottom: clamp(24px, (48/1366) * 100vw, 48px);
	}

	&__spoilers {
		display: flex;
		flex-direction: column;
		gap: clamp(32px, (48/1510) * 100vw, 48px) 0;
		margin-bottom: clamp(48px, (64/1510) * 100vw, 64px);
	}

	.company {
		color: #1c2691;
		pointer-events: none;
	}
}
