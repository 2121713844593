.arrow-link {
	--arrowLinkWidth: clamp(25px, (50/768)*100vw, 50px);
	--arrowLinkEdgeWidth: 8px;
	--arrowLinkHeight: 1.6px;
	--arrowLinkEdgeSpaceRight: -3px;
	cursor: pointer;

	span {
		display: inline-block;
		position: relative;
		top: 0;
		left:0;
		width: var(--arrowLinkWidth);
		height: var(--arrowLinkHeight);
		background-color: $black;

		&::before,
		&::after {
			content: '';
			position: absolute;
			width: var(--arrowLinkEdgeWidth);
			height: var(--arrowLinkHeight);
			top: calc(-1.45 * var(--arrowLinkHeight));
			right: var(--arrowLinkEdgeSpaceRight);
			background-color: $black;
			transform: rotate(45deg);
			border-radius: 5%;
		}

		&::before {
			top: calc(1.55 * var(--arrowLinkHeight));
			transform: rotate(-45deg);
		}
	}

	&--blue span {
		background-color: #1C2691;

		&::before,
		&::after {
			background-color: #1C2691;
		}
	}

}
