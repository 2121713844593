.search-page {
	width: 100%;

	&__body {
		position: relative;
	}

	&__title-page {
		font-weight: 400;
		font-size: 48px;
		line-height: 145%;
		color: $black;
		margin-bottom: 16px;
	}

	&__item {
		max-width: 50%;
		margin-bottom: 32px;

		&:first-child {
			margin-top: 64px;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 24px;
		line-height: 145%;
		color: $searchResultTitle;
		margin-bottom: 16px;

		a {
			color: $searchResultTitle;
		}
	}

	&__description {
		font-size: 16px;
		line-height: 145%;
		color: $black;
		overflow: hidden;
		@include textTruncateVertical(3);

		p:first-child {
			@include textTruncateVertical(3);
		}

		p:not(:first-child) {
			display: none;
		}

		img,
		iframe {
			display: none;
		}
	}

	@media (max-width: 1000px) {
		&__item {
			max-width: 100%;
		}
	}
}
