.about-info-text {
	width: 100%;

	p:not(:last-child) {
		margin-bottom: 24px;
	}

	b {
		font-weight: 600;
	}

	ul li {
		list-style: disc;
		margin-bottom: 16px;
		margin-left: 32px;
		padding-left: 12px;
	}

	ul {
		margin: 24px 0;
	}

	h4 {
		font-size: 28px;
		margin-bottom: 32px;
		font-family: $fontPlayfair;
	}
}
