.about-document {
	--aboutInfoSliderWidth: 302px;
	width: 100%;

	&__body {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__list {
		list-style: none !important;
		display: flex;
		margin: 0 !important;
	}

	&__item {
		position: relative;
		width: 85%;
		padding-bottom: 115%;
		margin: 0 !important;
		cursor: pointer;
		box-shadow: $defaultShadow;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: bottom;
		}
	}

	@media (max-width: 1134px) {
		&__list {
			justify-content: center;
			text-align: center;
			padding: 15px 0px;
		}

		&__item {
			text-align: center;
			width: 192px;
			padding-bottom: 0;
			height: 281px;
		}
	}

	@media (max-width: 1199px) {
		&__body {
			flex-direction: column;
			row-gap: 48px;
		}
	}

	@media (max-width: 565px) {
		&__item {
			width: 288px;
			height: 424px;
		}
	}
}
