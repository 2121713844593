.about-project {
	--iconSize: clamp(32px, (36/1510) * 100vw, 36px);
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	row-gap: clamp(32px, (48/1510) * 100vw, 48px);
	column-gap: clamp(32px, (48/1510) * 100vw, 48px);
	margin: clamp(32px, (48/1510) * 100vw, 48px) 0px !important;

	&__item {
		flex: 0 1 30%;
		list-style: none !important;
		position: relative;
		margin: 0 !important;
		cursor: default;

		&:hover {
			.about-project__item-icon {
				background: url(../img/icons/mountHover.svg) #fff no-repeat
					center / 20px 18px;
				box-shadow: 0px 4px 20px rgba(4, 28, 114, 0.35);
			}
		}
	}

	&__item-icon {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: var(--iconSize);
		height: var(--iconSize);
		border-radius: 50%;
		background: url(../img/icons/mount.svg) #061d38 no-repeat center / 20px
			18px;
		transition: all 0.1s ease;
	}

	&__item-text {
		--identLeft: clamp(12px, (16/1510) * 100vw, 16px);
		font-family: $fontPlayfair;
		letter-spacing: -0.5px;
		font-size: clamp(16px, (18/1510) * 100vw, 18px);
		color: $black;
		padding-left: calc((var(--iconSize) / 2) + var(--identLeft));
		white-space: nowrap;
	}

	@media (max-width: 1134px) {
		column-gap: 0;
	}

	@media (max-width: 1024px) {
		&__item {
			flex: 0 0 30%;
		}
	}

	@media (max-width: 767px) {
		&__item {
			flex: 0 0 50%;
		}
	}

	@media (max-width: 589px) {
		--iconSize: 36px;
		&__item {
			flex: 1 1 100%;
		}

		&__item-text {
			--identLeft: 16px;
			padding-left: calc((var(--iconSize) / 2) + var(--identLeft));
		}
	}
}
