.main-hero {
	--mainHeroEffectHeight: clamp(62px, (113/1510)*100vw, 113px);
	width: 100%;
	height: 100%;
	margin-top: calc(-1 * var(--contentPaddingTop));

	&__body {
		position: relative;
		width: 100%;
	}

	&__background {
		position: relative;
		width: 100%;
		height: clamp(320px, (887/1510)*100vw, 887px);
		background-color: rgba(0, 0, 0, .2);

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			z-index: -1;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: clamp(62px, (113/1510)*100vw, 113px);
			background:
				url(../img/mainHero/effect.svg)
				transparent
				no-repeat
				center / cover;
			z-index: $zIndexMainHeroEffect;
		}
	}

	&__about-info {
		position: absolute;
		top: 50%;
		left: 50%;
		transform:
			translateX(-50%)
			translateY(calc(-50% - var(--mainHeroEffectHeight) / 2));
		max-width: var(--container);
		padding: 0px var(--paddingContainer);
	}
}
