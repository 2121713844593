.title {
	display: inline-block;
	font-family: $fontPlayfair;
	font-size: var(--titleSize);
	font-weight: 400;
	color: $blueDark;

	&--big {
		--titleSize: clamp(22px, (64/1510)*100vw, 64px);
	}

	&--middle {
		--titleSize: clamp(22px, (48/1510)*100vw, 48px);
		margin-bottom: clamp(24px, (48/1510)*100vw, 48px);
	}

	&--small {
		--titleSize: 24px;
	}

	&--bold {
		font-weight: 700;
	}
}
