// media
$desktop: 1920;
$laptop: 1440;
$tablet: 768;
$mobile: 360;

:root {
	--fontSize: 16px;
	--headerHeight: clamp(54px, (96/1510) * 100vw, 96px);
	--headerPaddingTop: 14px;
	--footerPaddingVertical: clamp(32px, (82/1024) * 100vw, 82px);
	--contentPaddingTop: clamp(16px, (42/1510) * 100vw, 42px);
	--contentPaddingBottom: clamp(60px, (140/1510) * 100vw, 140px);
	--projectsDropdownListWidth: 398px;
	--backgroundDecorWidth: clamp(40px, (80/1510) * 100vw, 80px);
	--backgroundDecorItemHeigth: clamp(3.5px, (7/1510) * 100vw, 7px);
	--paddingContainer: 55px;
	--containerSpace: 520px;
	--container: calc(
		(
			#{$desktop + px} - (var(--containerSpace) -
						((var(--paddingContainer) * 2)))
		)
	);

	@media (max-width: 1099px) {
		--headerPaddingTop: 0;
	}

	@media (max-width: 991px) {
		--paddingContainer: clamp(16px, (40/991) * 100vw, 40px);
	}
}

$defaultFontFamily: 'Montserrat', sans-serif;
$fontPlayfair: 'Playfair Display SC', sans-serif;
$fontPlayfairDefault: 'Playfair Display', sans-serif;
$defaultShadow: 0px 4px 16px rgba(117, 117, 117, 0.12);
$defaultShadowHover: 0px 14px 16px rgba(117, 117, 117, 0.12);
$defaultTransition: all 0.3s ease;
$searchFocusAnimation: all 0.2s ease;

// colors
$black: #000000;
$white: #ffffff;
$blue: #181c6c;
$blueDark: #061d38;
$orange: #fcb15a;
$red: #e21e25;
$darkBlue: #061d38;
$grey: #e6e9ed;
$grey2: #9f9f9f;
$grey3: #8e8e8e;
$grey4: #f6f6f6;
$greyDark: #2f3233;
$greyBorder: #dddddd;
$strokeCard: #181c6c;
$contactTitleColor: #000000;
$breadcrumbsColor: #aeaeae;
$searchResultTitle: #29166f;

$searchBorder: 1px solid $greyBorder;

// z-index
$zIndexAboutSlider: 1;
$zIndexMainHeroEffect: 79;
$zIndexUpScroll: 80;
$zIndexHeader: 101;
$zIndexContactDropdown: 100;
$zIndexLogoDropdown: 99;
$zIndexProjectsDropdown: 99;
$zIndexContactDropdownClose: 99;
