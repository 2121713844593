.projects-dropdown-list {
	min-width: var(--projectsDropdownListWidth);
	padding: clamp(24px, (32/665)*100vw, 32px);
	border: 1px solid #CECECE;
	background-color: $white;

	&__item:not(:last-child) {
		margin-bottom: 32px;
	}

	&__link {
		font-size: 16px;
		font-weight: 600;
		color: $black;
		transition: color 0.2s ease;

		&:hover {
			color: $darkBlue;
		}
	}

	@media (max-width: 499px) {
		min-width: 100%;
	}
}
