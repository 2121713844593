.management-info {
	--zIndexImage: 1;
	--zIndexborderImageLine: 2;
	--borderImageLineSize: 70px;
	--managementHeight: clamp(288px, (438/1510)*100vw, 438px);

	width: 100%;
	min-height: var(--managementHeight);
	overflow: hidden;
	text-align: center;
	padding-bottom: 28px;
	@include animationLiftingInitial;

	&:hover {
		@include animationLiftingHover(1.05, $defaultShadowHover);
	}

	&__image {
		position: relative;
		width: 100%;
		margin: 0 auto 24px auto;
		background-color: $grey3;
		overflow: hidden;
		border-radius: 10px 10px 0 0;
		padding-bottom: 95%;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: var(--zIndexImage);
			object-position: 0;
		}
	}

	&__full-name {
		width: 100%;
		font-size: 16px;
		font-weight: 600;
		color: $black;
		margin-bottom: 12px;
		cursor: default;
		padding: 0px 10px;
		letter-spacing: -0.5px;
	}

	&__position {
		width: 90%;
		font-size: 14px;
		font-weight: 300;
		color: $darkBlue;
		cursor: default;
		padding: 0px 10px;
		margin: 0 auto;
	}

	@media (max-width: 929px) {
		&:hover {
			@include animationLiftingHover(1, $defaultShadow);
		}
	}

	@media (max-width: 766px) {
		--managementHeight: 288px;

		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		width: 55%;
		margin: 0 auto;
		padding-bottom: 5px;

		&__image {
			border-radius: 8px 8px 0 0;
			flex: 1 1 322px;
		}

		&__text {
			padding-bottom: 16px;
		}

		&__full-name {
			margin-bottom: 8px;
		}
	}

	@media (max-width: 430px) {
		&__image {
			flex: 1 1 100%;
		}
	}

	@media (max-width: 601px) {
		width: 100%;
	}
}