.projects-country-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: clamp(24px, (48/1510)*100vw, 48px) 0 64px 0;

	@media (max-width: 645px) {
		margin-top: 0;
	}
}
