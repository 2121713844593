* {
	padding: 0px;
	margin: 0px;
	border: 0px;
	-webkit-tap-highlight-color: transparent; // отключить синию обводку в Хроме
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

aside,
nav,
footer,
header,
section {
	display: block;
}

html {
	font-size: var(--fontSize);
	scroll-behavior: smooth;
}

body {
	font-family: $defaultFontFamily;
	font-weight: 400;
	line-height: 1.45;
	letter-spacing: 0.8px;
	font-size: 1rem;
	color: $black;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	touch-action: pan-x pan-y; // отключить зум на ios
	background-color: $white;
}

html,
body {
	width: 100%;
	min-height: 100%;
}

input,
button,
textarea {
	font-family: $defaultFontFamily;
	font-size: 1rem;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
	border: none;
	background-color: transparent;
	font-size: 1rem;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
	border-style: none;
	border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}

address {
	font-style: normal;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
	display: none;
}

.content {
	flex: 1 0 auto;
	margin-top: calc(var(--headerHeight) + var(--contentPaddingTop));
	padding-bottom: var(--contentPaddingBottom);

	&--not-found {
		padding: 0;
		margin: 0;
	}
}

.container {
	max-width: var(--container);
	height: 100%;
	padding: 0px var(--paddingContainer);
	margin: 0 auto;
}
