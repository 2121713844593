.footer {
	width: 100%;
	padding: var(--footerPaddingVertical) 0;
	background:
		url(../img/mainBackground.png)
		transparent
		no-repeat
		0 / cover;

	&__body {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__title {
		margin-bottom: clamp(24px, (32/768)*100vw, 32px);
	}

	&__marketing,
	&__contact,
	&__project,
	&__about {
		flex: 0 1 auto;
	}

	&__social {
		--contactSocialSize: 46px;
		margin-top: 28px;
		display: none;
	}

	@media (max-width: 1199px) {
		&__body {
			flex-wrap: wrap;
			justify-content: space-between;
			row-gap: clamp(32px, (64/1199)*100vw, 64px);
		}

		&__project,
		&__contact {
			flex: 0 1 65%;
		}

		&__about,
		&__marketing {
			flex: 0 1 35%;
		}
	}

	@media (max-width: 680px) {
		&__body {
			row-gap: 0;
		}

		&__project,
		&__contact,
		&__about,
		&__marketing {
			flex: 0 1 100%;
			margin-bottom: clamp(32px, (64/1199)*100vw, 64px);
		}

		&__about {
			margin-bottom: 0;
		}

		&__social {
			display: flex;
			justify-content: center;
			margin-top: 0;
		}
	}
}
