.main-news {
	--sectionPaddingY: clamp(60px, (140/1510)*100vw, 140px);
	width: 100%;
	height: 100%;
	padding-top: var(--sectionPaddingY);
	&__wrapper {
		width: 100%;
		height: 100%;
	}

	&__header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__button {
		align-self: flex-end;
		margin-bottom: 15px;

		&--mobile {
			display: none;
		}
	}

	&__body-wrapper {
		width: 100%;
		display: flex;
	}

	&__priority {
		flex: 0 0 55%;
		padding-right: 100px;
	}

	&__list {
		flex: 0 0 45%;
		padding-left: 80px;
		border-left: 1px solid $greyBorder;
	}

	@media (max-width: 1366px) {
		&__priority {
			padding-right: 40px;
		}

		&__list {
			padding-left: 55px;
		}
	}

	@media (max-width: 1099px) {
		&__body {
			display: flex;
			justify-content: center;
		}

		&__body-wrapper {
			width: auto;
			display: block;
			padding-left: clamp(50px, (150/1099)*100vw, 150px);
			padding-right: clamp(50px, (150/1099)*100vw, 150px);
		}

		&__button {
			font-size: 16px;
			margin-bottom: 5px;
		}

		&__priority {
			width: 100%;
			padding-right: 0;
			padding-bottom: clamp(14px, (16/768)*100vw, 16px);
			border-bottom: 1px solid $greyBorder;
		}

		&__list {
			width: 100%;
			padding-left: 0;
			border-left: 1px solid transparent;
		}
	}

	@media (max-width: 849px) {
		&__body-wrapper {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@media (max-width: 525px) {
		&__button {
			display: none;

			&--mobile {
				--contentButtonLine: 0;
				top: 38px;
				display: block;
				margin: 0 auto;

				&:hover {
					--contentButtonLine: 0;
				}
			}
		}
	}
}
