.footer-project {

	&__item {
		margin-bottom: 16px;

		&:hover .footer-project__link {
			opacity: 0.65;
		}
	}

	&__link {
		display: flex;
		align-items: center;
		font-weight: 500;
		color: $black;
		transition: opacity 0.15s ease;
		font-size: clamp(14px, (16/1024)*100vw, 16px);
		overflow: hidden;
		// text-overflow: ellipsis;
		max-width: 210px;
		display:inline-block;
		white-space: wrap;
	}

	&__social {
		--contactSocialSize: 46px;
		margin-top: 28px;
		display: none;
	}

	@media (max-width: 1199px) {
		&__social {
			display: flex;
		}
	}

	@media (max-width: 680px) {
		&__social {
			display: none;
		}
	}
}
