.main-about {
	--mainAboutTopIdent: clamp(32px, (100/1510) * 100vw, 100px);
	transform: translateY(calc(-1 * var(--mainAboutTopIdent)));
	margin-bottom: 20px;

	&__body {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-left: 60px;
	}

	&__title {
		margin-bottom: clamp(24px, (32/1510) * 100vw, 32px);
		font-size: clamp(36px, (48/1510) * 100vw, 48px);
	}

	&__images {
		flex: 0 1 572px;
		display: flex;
		align-items: flex-start;
	}

	&__image {
		--zIndexImageLarge: 1;
		--zIndexImageSmall: 2;
		--zIndexImageEffect: 3;
		position: relative;

		&--large {
			flex: 0 0 386px;
			height: 581px;
			z-index: var(--zIndexImageLarge);

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				background: url(../img/icons/effectImage.svg) transparent
					no-repeat 0 / cover;
				width: 100%;
				height: 67px;
				z-index: var(--zIndexImageEffect);
			}
		}

		&--small {
			flex: 0 0 285px;
			height: 380px;
			align-self: flex-end;
			transform: translateX(-100px);
			z-index: var(--zIndexImageSmall);
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: scale(0.998);
		}
	}

	&__text {
		flex: 0 0 650px;
		display: flex;
		flex-direction: column;
		transform: translateY(calc(var(--mainAboutTopIdent) / 2));
	}

	&__info {
		line-height: 25px;
		margin-bottom: clamp(24px, (32/1510) * 100vw, 32px);
		font-size: clamp(14px, (16/991) * 100vw, 16px);
	}

	&__list {
		display: flex;
		gap: 32px 45px;
		flex-wrap: wrap;
		max-width: 83%;
		margin-bottom: clamp(24px, (32/1510) * 100vw, 32px);
	}

	&__item {
		flex: 1 0 220px;
		display: flex;
		align-items: center;
		column-gap: 24px;
	}

	&__item-num {
		font-family: $fontPlayfair;
		font-weight: 700;
		font-size: 48px;
	}

	&__item-text {
		font-size: clamp(14px, (16/991) * 100vw, 16px);
	}

	&__button {
		align-self: flex-start;
		font-size: clamp(16px, (22/1510) * 100vw, 22px);
	}

	@media (max-width: 1450px) {
		&__image {
			&--large {
				flex: 0 1 386px;
				padding-bottom: 90%;
				height: auto;
			}

			&--small {
				flex: 0 1 285px;
				padding-bottom: 59.5%;
				height: auto;
			}
		}
	}

	@media (max-width: 1366px) {
		&__body {
			padding-left: 0;
		}
	}

	@media (max-width: 1240px) {
		&__images {
			display: none;
		}

		&__text {
			flex: 0 1 80%;
			transform: translateY(var(--mainAboutTopIdent));
		}
	}

	@media (max-width: 991px) {
		&__text {
			flex: 1 1 100%;
		}

		&__button {
			font-size: clamp(14px, (16/991) * 100vw, 16px);
		}
	}

	@media (max-width: ($tablet + px)) {
		&__title {
			font-size: clamp(22px, (36 /#{$tablet}) * 100vw, 36px);
		}
	}

	@media (max-width: 699px) {
		&__list {
			justify-content: space-between;
			max-width: 100%;
		}
	}

	@media (max-width: 605px) {
		&__list {
			flex-direction: column;
			gap: 0;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-start;
			row-gap: 16px;
			margin: 0 auto;
			margin-bottom: 24px;
		}

		&__item {
			flex-direction: column;
			flex: 0 0 auto;
			column-gap: 0;
			row-gap: 12px;
		}

		&__button {
			align-self: center;
		}
	}
}
