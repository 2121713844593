.pagination {
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0 6px;

	&__item {
		height: 100%;
		color: $grey2;
		padding: 0 5px;
		transition: all 0.15s ease;

		&:nth-child(2) {
			margin-left: clamp(6px, (24/768) * 100vw, 24px);
		}

		&:nth-last-child(2) {
			margin-right: clamp(6px, (24/768) * 100vw, 24px);
		}

		&.current {
			font-size: 22px;
			color: $black;
		}

		&:not(.pagination__item.current):hover {
			font-size: 16px;
			color: $black;
		}
	}

	&__button {
		height: 100%;
		color: $grey2;
		padding: 0 5px;
		transition: all 0.15s ease;
	}

	&__arrow {
		width: 100%;
		height: 100%;

		&--prev {
			transform: rotate(180deg);
		}
	}
}
