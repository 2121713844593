.projects-country-item {
	--projectsCountryItemHeight: 350px;
	--projectsCountryItemPadding: 32px;
	position: relative;
	flex: 0 1 33.3%;
	min-height: var(--projectsCountryItemHeight);
	padding: var(--projectsCountryItemPadding);
	border: 1px solid $greyBorder;
	border-left: 1px solid transparent;

	&:hover {
		.projects-country-item__arrow {
			animation: animationArrowLink 1.4s linear infinite 0.3s;
		}
	}

	&__link {
		display: inline-block;
		width: 100%;
		height: 100%;
	}

	&__image {
		position: relative;
		width: 100%;
		height: 216px;
		margin-bottom: 24px;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 4s ease;
			z-index: 2;
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: -1px;
			left: 0;
			background:
				url(../img/icons/effectImage.svg)
				transparent
				no-repeat
				0 / cover;
			width: calc(100% + 1px);
			height: 67px;
			z-index: 3;
		}
	}

	&__no-image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background:
			url(../img/icons/backgroundProject.svg)
			#ECEFF3
			no-repeat
			center / clamp(70px, (99/991)*100vw, 99px);
		z-index: 1;
	}

	&__title {
		font-size: clamp(14px, (18/1024)*100vw, 18px);
		font-weight: 600;
		cursor: default;
		color: $black;
	}

	&__arrow {
		position: absolute;
		bottom: var(--projectsCountryItemPadding);
		right: var(--projectsCountryItemPadding);
	}

	@media (max-width: 1199px) {
		--projectsCountryItemPadding: clamp(24px, (65/1510)*100vw, 65px);
		flex: 0 1 50%;

		&:nth-child(even) {
			border-right: 1px solid transparent;
		}
	}

	@media (max-width: 645px) {
		--projectsCountryItemPadding: clamp(10px, (65/645)*100vw, 65px);
		flex: 0 1 100%;
		border-right: 1px solid transparent;
		padding-bottom: 24px;

		&:first-child {
			border-top: 1px solid transparent;
		}

		&__arrow {
			bottom: 24px;
		}
	}

	@media (max-width: 350px) {
		padding: 24px 0;
	}

	@media (max-width: 495px) {
		padding-left: 15px;
		padding-right: 15px;

	}
}

@keyframes animationArrowLink {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(5px);
	}

	100% {
		transform: translateX(0);
	}
}
