.project-inside {
	--projectInsideSliderHeight: 478px;
	width: 100%;

	&__body {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		gap: 64px 0;
	}

	&__row {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 0 128px;
	}

	&__slider-wrapper {
		height: var(--projectInsideSliderHeight);
		width: 691px;
		margin-right: 128px;
		margin-bottom: 40px;
		overflow: hidden;
		float: left;
	}

	&__slider-text {
		display: block;
	}

	&__slider {
		width: 100%;
		height: 100%;
	}

	&__info {
		width: auto;
		display: block;

		&--full-width {
			flex: 1 1 100%;
		}

		ul li {
			display: list-item;
			list-style: outside disc;
			margin-left: 20px;

			&:not(:last-child) {
				margin-bottom: 24px;
			}

			&::marker {
				font-size: 24px;
				line-height: 1;
			}
		}

		p,
		h4,
		h5,
		h6 {
			margin-bottom: 24px;
		}

		h4,
		h5,
		h6 {
			font-weight: 600;
		}

		table {
			width: 100% !important;
			border-top: 1px solid $grey3;

			th {
				padding: 16px 20px;
				border-bottom: 1px solid $grey3;

				p {
					text-align: center;
					margin-bottom: 0;
				}

				&:not(:last-child) {
					border-right: 1px solid $grey3;
				}
			}

			td {
				border-bottom: 1px solid $grey3;
				padding: 16px 20px;

				p {
					display: flex;
					align-items: center;
					margin-bottom: 0;
				}

				&:first-child {
					font-weight: 600;
				}

				&:not(:last-child) {
					border-right: 1px solid #8e8e8e;
				}
			}

			tbody {
				tr {
					width: 50%;

					&:first-child {
						border-bottom: 1px solid $grey3;
					}
				}
			}
		}
	}

	&__table {
		flex: 1 1 691px;
	}

	&__map {
		flex: 1 1 572px;
		min-height: 505px;
	}

	@media (max-width: 1366px) {
		--projectInsideSliderHeight: auto;
		&__body {
			gap: clamp(60px, (100/1366) * 100vw, 100px);
			justify-content: flex-start;
		}

		&__slider-text {
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: flex-start;
			gap: 48px;
		}

		&__slider-wrapper {
			flex: 0 1 auto;
			width: 100%;
			height: var(--projectInsideSliderHeight);
			overflow: hidden;
			padding: 0px 90px;
			float: none;
			margin-right: 0;
		}

		&__info {
			flex: 1 1 auto;
			width: 100%;
		}

		&__table,
		&__map {
			flex: 1 1 auto;
		}

		&__row {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 48px;
		}
	}

	@media (max-width: ($tablet + px)) {
		&__slider-wrapper {
			padding: 0;
		}
	}

	@media (max-width: 645px) {
		&__map {
			min-height: clamp(258px, (505/645) * 100vw, 505px);
		}
	}
}
