.services-engineering {
	--gap: 20px;
	width: 100%;

	&__title {
		margin-bottom: 32px;
	}

	&__cards {
		width: 92%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: var(--gap);
	}

	&__card {
		min-height: 166px;
		flex: 0 0 100%;

		h4 {
			font-size: clamp(18px, (22/1510)*100vw, 22px);
			font-weight: 600;
		}

		p {
			margin-top: 8px;
			font-size: 18px;
			@include textTruncateVertical(2);
		}
	}

	@media (max-width: 1510px) {
		&__cards {
			width: 100%;
		}
	}

	@media (max-width: 754px) {
		&__card {
			h4 {
				font-size: clamp(16px, (18/754)*100vw, 18px);
			}
		}
	}
}
