.contact-reception {
	width: 100%;
	height: 100%;

	&__title {
		font-size: 16px;
		font-weight: 400;
		color: $contactTitleColor;
		margin-bottom: 26px;
	}

	&__item {
		color: $black;
		font-weight: 500;

		&:hover > a {
			opacity: 0.65;
		}

		> a {
			display: flex;
			align-items: center;
			color: $black;
			font-weight: 500;
			transition: opacity 0.15s ease;
		}

		&:not(:last-child) {
			margin-bottom: 21px;
		}
	}

	&__icon {
		margin-right: 8px;
	}

	@media (max-width: 645px) {
		&__title {
			margin-bottom: 24px;
		}

		&__item {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
}
