.services-card {
	--servicesCardIconSize: clamp(41px, (67/1155) * 100vw, 67px);
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: center;
	padding: 0 clamp(24px, (32/1510) * 100vw, 32px);
	background-color: $white;
	font-size: clamp(14px, (18/768) * 100vw, 18px);
	max-width: auto;
	border-radius: 10px;
	@include animationLiftingInitial;

	&__body {
		text-align: left;
		display: flex;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	&__left {
		flex: 0 1 45%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		border-right: 1px solid #181c6c21;
		padding: clamp(24px, (32/1510) * 100vw, 32px) 15px;
	}

	&__right {
		flex: 0 1 55%;
		height: 100%;
		padding-left: 58px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		ul:first-child li {
			list-style: disc;

			&::marker {
				font-size: 14px;
			}
		}

		ul {
			padding: clamp(24px, (32/1510) * 100vw, 32px) 0;
		}

		li {
			font-size: clamp(16px, (22/1510) * 100vw, 22px);
		}
	}

	&__icon {
		width: var(--servicesCardIconSize);
		height: var(--servicesCardIconSize);
		fill: $darkBlue;
		transition: all 0.4s ease;
		margin-bottom: clamp(10px, (24/991) * 100vw, 24px);
	}

	&__text {
		font-size: clamp(14px, (18/768), 18px);
		color: $darkBlue;
		cursor: default;
		transition: all 0.4s ease;
	}

	&__sub-title {
		list-style: none !important;
		padding-top: 0 !important;

		margin-top: -20px;
		margin-left: 15px;

		li::marker {
			list-style: none !important;
			display: none;
		}
	}

	&:hover {
		@include animationLiftingHover(1.025, $defaultShadowHover);
	}

	@media (max-width: 754px) {
		&__body {
			width: 100%;
			flex-direction: column;
			padding: clamp(24px, (32/754) * 100vw, 32px)
				clamp(14px, (16/754) * 100vw, 16px) 0
				clamp(14px, (16/754) * 100vw, 16px);
			justify-content: center;
			align-items: center;
		}

		&__left {
			border: none;
			align-items: center;
			max-width: 380px;
		}

		&__left,
		&__right {
			padding: 0 0 0 0 !important;
			flex: 1 1 100%;
		}

		&__right {
			align-self: flex-start;

			ul li {
				&::marker {
					font-size: 12px;
				}
			}

			li {
				font-size: clamp(14px, (16/754) * 100vw, 16px);
			}
		}
	}
}
