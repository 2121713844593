.main-projects {
	--mainProjectsHeight: clamp(488px, (761/1510) * 100vw, 761px);

	--slideWidth: clamp(254px, (453/991) * 100vw, 453px);
	--slideHeght: clamp(278px, (465/991) * 100vw, 465px);

	--sliderWidth: calc((var(--sliderWidth) * 3) - 48px);

	position: relative;
	width: 90%;
	margin: 0 auto;
	min-height: var(--mainProjectsHeight);
	user-select: none;
	background-color: #f8f8f8;
	padding: 64px 0px;

	&__wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__header {
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: clamp(24px, (64/1510) * 100vw, 64px);
	}

	&__button {
		font-size: clamp(16px, (22/1510) * 100vw, 22px);

		&--mobile {
			display: none;
			font-size: clamp(14px, (16/699) * 100vw, 16px);
			justify-content: flex-start;
			white-space: nowrap;
		}
	}

	&__title {
		margin-bottom: 0;
		font-size: clamp(36px, (48/1510) * 100vw, 48px);
	}

	&__body {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		overflow: hidden;
		align-self: center;
		padding: clamp(4px, (25/991) * 100vw, 25px) 0
			clamp(16px, (25/991) * 100vw, 25px) 0;
	}

	&__slider {
		display: flex;
		overflow: visible !important;
	}

	&__slide {
		flex: 0 0 var(--slideWidth);
		height: var(--slideHeght) !important;
		background-color: transparent;
	}

	&__slide-body {
		box-shadow: $defaultShadow;
		background-color: $white;
		display: flex;
		flex-direction: column;
		max-width: 100%;
		height: 100%;
		transform: scale(0.8);
		padding: clamp(20px, (24/405) * 100vw, 24px);
		transition: transform 0.3s ease;
	}

	&__slide-active .main-projects__slide-body {
		transform: scale(1) translateX(0);
	}

	&__bullets {
		--bulletSize: clamp(6px, (9 /#{$tablet}) * 100vw, 9px);
		--bulletGap: clamp(8px, (12/991) * 100vw, 12px);
		position: absolute;
		bottom: 32px !important;
		width: 100%;
		height: 40px;
		background-color: transparent;
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: var(--bulletGap);

		.swiper-pagination-bullet {
			margin: 0 !important;
			width: var(--bulletSize);
			height: var(--bulletSize);
			background-color: transparent;
			border: 1.5px solid $black;
			opacity: 1;
		}

		.swiper-pagination-bullet-active {
			background-color: $black;
		}
	}

	&__navigation {
		--navButtonSize: 48px;
		position: absolute;
		top: calc(50% + 45px);
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		display: flex;
		justify-content: space-between;
		max-width: var(--container);
		padding: 0px var(--paddingContainer);
		z-index: 1000;
	}

	&__next,
	&__prev {
		width: var(--navButtonSize);
		height: var(--navButtonSize);
		border-radius: 50%;
		box-shadow: $defaultShadow;
		background: url(../img/icons/arrowControlSlideHover.svg) $white
			no-repeat center / 24px;
	}

	&__prev {
		transform: rotate(180deg);
	}

	&__slide-image {
		position: relative;
		width: 100%;
		padding-bottom: 76%;
		margin-bottom: clamp(12px, (16/1510) * 100vw, 16px);

		img {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__slide-title {
		font-size: clamp(14px, (16/1510) * 100vw, 16px);
		font-weight: 600;
		text-transform: uppercase;
		@include textTruncateVertical(2);
	}

	&__slide-button {
		font-family: $defaultFontFamily;
		font-weight: 400;
		align-self: flex-start;
		margin-top: auto;
		font-size: clamp(12px, (16/1510) * 100vw, 16px);
	}

	@media (max-width: 1720px) {
		width: 100%;

		&__navigation {
			max-width: 100%;
		}
	}

	@media (max-width: 1574px) {
		&__navigation {
			padding: 0 20px;
		}
	}

	@media (max-width: 1495px) {
		&__navigation {
			display: none;
		}
	}

	@media (max-width: 991px) {
		background-color: transparent;
	}

	@media (max-width: ($tablet + px)) {
		--slideWidth: clamp(288px, (363/405) * 100vw, 363px);
		--slideHeght: clamp(315px, (372/405) * 100vw, 372px);

		&__title {
			margin-bottom: 0;
			font-size: clamp(22px, (36 /#{$tablet}) * 100vw, 36px);
		}

		&__slide-title {
			font-size: clamp(12px, (14 /#{$tablet}) * 100vw, 14px);
		}
	}

	@media (max-width: 699px) {
		&__button {
			display: none;

			&--mobile {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				display: block;
			}
		}
	}

	@media (max-width: 405px) {
		&__slide-image {
			padding-bottom: 72%;
		}
	}
}
