.contact-info {
	width: 100%;
	height: 100%;
	background: url(../img/mainBackground.png) transparent no-repeat 0 / cover;

	&__body {
		padding: 24px;
	}

	&__address {
		font-size: 22px;
		color: $black;
		font-weight: 300;
		margin-bottom: 30px;
	}

	&__row {
		display: flex;
		column-gap: 24px;
	}

	&__reception {
		flex: 1 1 50%;
	}

	&__marketing {
		flex: 1 1 50%;
	}

	@media (max-width: 991px) {
		&__address {
			font-size: clamp(18px, (22/991) * 100vw, 22px);
			margin-bottom: 22px;
		}
	}

	@media (max-width: 645px) {
		&__row {
			flex-direction: column;
		}

		&__reception {
			margin-bottom: 22px;
		}
	}
}
