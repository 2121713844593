.management {
	--mainManagementPaddingTop: clamp(60px, (140/1510) * 100vw, 140px);
	--buttonControlWidth: 29px;
	--sliderBulletSize: 8px;

	position: relative;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: 1;

	.swiper {
		padding-bottom: clamp(24px, (40/766) * 100vw, 40px);
	}

	&__body {
		margin-top: var(--mainManagementPaddingTop);
	}

	&__title {
		margin-bottom: clamp(24px, (64/1510) * 100vw, 64px);
	}

	&__list {
		display: flex;
		flex-wrap: nowrap;
		gap: 48px 18px;

		&--mobile {
			display: none;
		}
	}

	&__pagination {
		bottom: -10px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: calc(var(--sliderBulletSize) * 4);
	}

	&__bullet {
		display: inline-block;
		width: var(--sliderBulletSize);
		height: var(--sliderBulletSize);
		border-radius: 50%;
		background: transparent;
		border: 1px solid $black;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 8px;
		}

		&--active {
			background: $black;
		}
	}

	&__item {
		flex: 0 1 336px;
	}

	@media (max-width: 929px) {
		&__list {
			flex-wrap: wrap;
			justify-content: space-around;

			&--mobile {
				display: none;
			}
		}
	}

	@media (max-width: 766px) {
		&__list {
			display: none;

			&--mobile {
				display: flex;
			}
		}

		&__item {
			flex: 0 0 50%;
		}
	}
}
