.main-news-list {
	--mainNewsListWidth: 546px;
	--mainNewsListImageSize: 158px;
	--mainNewsItemPaddingVertical: 16px;

	width: var(--mainNewsListWidth);
	height: auto;

	&__item {
		position: relative;
		width: 100%;
		display: flex;
		padding-bottom: var(--mainNewsItemPaddingVertical);
		cursor: pointer;
		&:not(:first-child) {
			padding-top: var(--mainNewsItemPaddingVertical);
		}
		&:hover {
			.button-link {
				--contentButtonLine: 100%;
			}
		}

		&:not(:last-child) {
			border-bottom: 1px solid $greyBorder;
		}
	}

	&__date {
		display: inline-block;
		font-size: clamp(14px, (16/768)*100vw, 16px);
		font-weight: 300;
		color: $grey2;
		margin-bottom: 16px;
	}

	&__title {
		font-size: 16px;
		font-weight: 600;
		color: $black;
		height: 70px;
		padding-right: 20px;

		div {
			@include textTruncateVertical(3);
		}
	}

	&__info {
		flex: 1 1 auto;
	}

	&__image {
		position: relative;
		flex: 0 0 var(--mainNewsListImageSize);
		height: var(--mainNewsListImageSize);

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__button {
		position: absolute;
		bottom: var(--mainNewsItemPaddingVertical);
		font-size: 16px;
	}

	@media (max-width: 1099px) {
		--mainNewsListWidth: 100%;
		--mainNewsItemPaddingVertical: clamp(16px, (24/768)*100vw, 24px);

		&__item {
			padding-top: var(--mainNewsItemPaddingVertical);
		}
	}

	@media (max-width: 525px) {
		&__item {
			display: block;

			&:last-child {
				border-bottom: 1px solid transparent;
			}
		}

		&__image {
			display: none;
		}

		&__button {
			position: relative;
			top: 0px;
			margin-top: 24px;
			font-size: 16px;
		}
	}
}
