.footer-contact {
	&__address {
		font-weight: 500;
		color: $black;
		margin-bottom: 24px;
	}

	&__item {
		margin-bottom: 16px;

		&:not(:nth-child(2)):hover .footer-contact__link {
			opacity: 0.65;
		}

		&:nth-child(2) {
			a {
				display: inline-block;

				// &:last-child {
				// 	margin-left: 5px;
				// }

				&:hover {
					opacity: 0.65;
				}
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		font-weight: 500;
		color: $black;
		transition: opacity 0.15s ease;
		font-size: clamp(14px, (16/1024) * 100vw, 16px);
	}

	&__social {
		--contactSocialSize: 46px;
		margin-top: 28px;
	}

	@media (max-width: 1199px) {
		&__social {
			display: none;
		}
	}
}
